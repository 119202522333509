/* eslint-disable react-hooks/rules-of-hooks */
import { useApolloClient } from "@apollo/client";
import {
  CATEGORY_CONTENT,
  CONTENT_TYPES,
  SearchContentListQueries,
  SearchCourseListQueries,
  mapFetchALL,
  useContentActions,
  usePage,
} from "@platformx/authoring-apis";
import {
  RootState,
  clearComment,
  previewContent,
  updateContentList,
} from "@platformx/authoring-state";
import {
  OLD_CONTENT_TYPES,
  ShowToastError,
  capitalizeFirstLetter,
  makeCreateContentPath,
} from "@platformx/utilities";
import * as Sentry from "@sentry/react";
import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom-v6";
import CreateNewForm from "../../pages/formbuilder/CreateNewForm";
import CreateNewPage from "../../pages/page/CreateNewPage";
import ContentListing from "../ContentListing/ContentListing";
import ContentListingHeader from "../ContentListingHeader/ContentListingHeader";

const ContListingContainer = ({
  contentType,
  schemaType,
}: {
  contentType: string;
  schemaType: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { content, routes } = useSelector((state: RootState) => state);
  const { title } = routes?.routes.find((x) => x.id === contentType) || {};
  const { contentArray } = content;
  const client = useApolloClient();
  const location = useLocation();
  const { t } = useTranslation();
  const { state } = location;
  const [queryObject, setQueryObject] = useState({
    startIndex: 0,
    filter: "ALL",
  });
  const [isSpinning, setIsSpinning] = useState(false);
  const [isDialogOpen, setOpenCreatePage] = useState(false);
  const [loader, setloader] = useState(true);
  const contentVariables: any = mapFetchALL(
    state,
    queryObject.filter,
    contentType,
    {
      start: queryObject.startIndex,
      rows: 20,
    },
    schemaType,
  );
  const fetchListingData = async () => {
    try {
      const variableCourse: any = { ...contentVariables, filter: "Course", isListing: true };
      const fetchQuery =
        contentType?.toLocaleLowerCase() === "course"
          ? SearchCourseListQueries.FETCH_COURSE_LIST
          : SearchContentListQueries.FETCH_CONTENT_TYPE_LIST;

      const variables =
        contentType?.toLocaleLowerCase() === "course" ? variableCourse : contentVariables;
      const response = await client.query({
        query: fetchQuery,
        variables: OLD_CONTENT_TYPES.some(
          (item) => item.toLocaleLowerCase() === variables?.contentType?.toLocaleLowerCase(),
        )
          ? { ...variables, contentType: capitalizeFirstLetter(contentType) }
          : { ...variables },
      });
      if (queryObject.startIndex > 0) {
        const newContent = [
          ...contentArray,
          ...(response?.data?.authoring_getContentTypeItems ||
            response?.data?.authoring_recentContents ||
            // response?.data?.authoring_getContentListing ||
            []),
        ];
        dispatch(previewContent({}));
        dispatch(clearComment());
        dispatch(updateContentList(newContent));
      } else if (queryObject.startIndex === 0) {
        const newContent = [
          ...(response?.data?.authoring_getContentTypeItems ||
            response?.data?.authoring_recentContents ||
            // response?.data?.authoring_getContentListing ||
            []),
        ];
        setIsSpinning(false);
        dispatch(previewContent({}));
        dispatch(clearComment());
        dispatch(updateContentList(newContent));
      }
    } catch (err: any) {
      console.error(err);
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError(t("api_error_toast"), statusCode);
      Sentry.captureException(err);
    } finally {
      setloader(false);
    }
  };

  //Triggered when the filter is changed and on load
  useEffect(() => {
    fetchListingData();
  }, [queryObject]);

  //Triggered when the common search is changed and when left menu is navigated.
  useEffect(() => {
    if (state || contentType) {
      setloader(true);
      //filter and startindex is reset to default when the content type is changed.
      setQueryObject({ ...queryObject, startIndex: 0, filter: "ALL" });
    }
  }, [state, contentType]);

  const closeButtonHandle = () => {
    setOpenCreatePage(false);
  };

  const {
    deleteContent,
    duplicate,
    preview,
    unPublish,
    view,
    edit,
    editDynamicContent,
    editPage,
    fetchContentDetails,
    duplicateToSite,
    loading: deleteLoading,
  } = useContentActions("ALL");
  const { handlePageDelete, loading: deletePageLoading } = usePage();

  const memoizedMethods = useMemo(
    () => ({
      deleteContent,
      duplicate,
      preview,
      unPublish,
      view,
      edit,
      editPage,
      fetchContentDetails,
      duplicateToSite,
      handlePageDelete,
      editDynamicContent,
    }),
    [
      deleteContent,
      duplicate,
      preview,
      unPublish,
      view,
      edit,
      editPage,
      fetchContentDetails,
      duplicateToSite,
      handlePageDelete,
      editDynamicContent,
    ],
  );

  const createContentNew = () => {
    if (
      contentType?.trim()?.toLowerCase() === "sitepage" ||
      contentType?.trim()?.toLowerCase() === "formbuilder"
    ) {
      setOpenCreatePage(true);
    } else {
      const navigateTo = makeCreateContentPath(contentType);
      navigate(navigateTo, {
        state: contentType?.trim()?.toLowerCase(),
      });
    }
  };

  const handleFilter = (filter: string) => {
    const newQueryObject = { ...queryObject, filter: filter, startIndex: 0 };
    setQueryObject(newQueryObject);
    setloader(true);
  };

  const handleRefresh = () => {
    setloader(true);
    setIsSpinning(true);
    const newQueryObject = { ...queryObject, startIndex: 0 };
    setQueryObject(newQueryObject);
  };

  const fetchMoreData = () => {
    const newQueryObject = { ...queryObject, startIndex: queryObject.startIndex + 20 };
    setQueryObject(newQueryObject);
  };

  const deleteContentType = (contentTypeItem) => {
    memoizedMethods.deleteContent(
      contentTypeItem,
      {
        ...queryObject,
        startIndex: 0,
      },
      schemaType,
    );
  };
  const deletePageList = (pageItem) => {
    handlePageDelete(pageItem, {
      ...queryObject,
      startIndex: 0,
    });
  };

  return (
    <>
      <ContentListingHeader
        handleFilter={handleFilter}
        title={
          contentType?.toLocaleLowerCase() === "all"
            ? "Result"
            : contentType?.toLocaleLowerCase() === "sitepage"
              ? "Page"
              : title || contentType
        }
        category={CATEGORY_CONTENT}
        subCategory={CONTENT_TYPES}
        handleAddNew={createContentNew}
        handleRefresh={handleRefresh}
        animationState={isSpinning}
        filterValue={queryObject.filter}
      />
      <ContentListing
        content={contentType}
        loader={loader}
        deleteContent={deleteContentType}
        deletePage={deletePageList}
        fetchMoreData={fetchMoreData}
        preview={memoizedMethods.preview}
        unPublish={memoizedMethods.unPublish}
        view={memoizedMethods.view}
        edit={memoizedMethods.edit}
        editDynamicContent={memoizedMethods.editDynamicContent}
        editPage={memoizedMethods.editPage}
        deleteContentLoading={deleteLoading}
        deletePageLoading={deletePageLoading}
        duplicate={memoizedMethods.duplicate}
        fetchContentDetails={memoizedMethods.fetchContentDetails}
        duplicateToSite={memoizedMethods.duplicateToSite}
        schemaType={schemaType}
      />

      {contentType?.trim()?.toLowerCase() === "sitepage" && (
        <CreateNewPage isDialogOpen={isDialogOpen} closeButtonHandle={closeButtonHandle} />
      )}
      {contentType?.trim()?.toLowerCase() === "formbuilder" && (
        <CreateNewForm isDialogOpen={isDialogOpen} closeButtonHandle={closeButtonHandle} />
      )}
    </>
  );
};

export default memo(ContListingContainer);
